<script>
import Swal from 'sweetalert2';
import Moment from 'moment';
import VTextbox from '../components/Textbox.vue';
import VButton from '../components/Button.vue';
import VCards from '../components/Cards.vue';
import VSteps from '../components/Steps.vue';
import MoneyMask from '../helpers/money-mask';
import VLoading from '../components/Loading.vue';

export default {
  name: 'Payment',
  components: {
    VTextbox,
    VButton,
    VCards,
    VSteps,
    VLoading
  },
  data() {
    return {
      codBarras: '',
      valor: '',
      disableValor: false,
      descricao: {
        traders: {}
      },
      idContaCartaoDebitado: null,
      hasHourError: false,
      loading: false
    };
  },
  beforeMount() {
    if (this.$route.query.c) this.idContaCartaoDebitado = this.$route.query.c;
    this.getBankHour();
  },
  methods: {
    MoneyMask,
    async updateCodBarras() {
      this.loading = true;
      // this.$store.commit('pageLoading', true);
      let barCodeCleaned = this.codBarras;
      barCodeCleaned = barCodeCleaned.replace(/\D/g, '');
      const size = barCodeCleaned.length
      if (size) this.$validator.errors.remove('codBarras');
      this.descricao = {
        traders: {}
      };
      this.valor = '';
      this.hasHourError = false;
      this.$nextTick(async () => {
        if (size !== 44 && size !== 47 && size !== 48) {
          this.loading = false;
          // this.$store.commit('pageLoading', false);
          return false
        }
        // barCodeCleaned = barCodeCleaned.replace(/\./g, '');
        // barCodeCleaned = barCodeCleaned.replace(/-/g, '');
        this.$store.commit('formLoading', true);
        await this.$http.get(`/transfers/payments/${barCodeCleaned}`)
          .then(({ data }) => {
            this.disableValor = false;
            this.descricao = data.result.paymentInfoNPC
            if (this.descricao.dueDate) {
              this.descricao.dueDate = Moment(this.descricao.dueDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
            }
            let valor;
            if (data.result.paymentInfoNPC.computedBillValues.totalAmountToCharge === 0) {
              valor = data.result.paymentInfoNPC.billValue
            } else if (data.result.paymentInfoNPC.computedBillValues.totalAmountToCharge) {
              valor = data.result.paymentInfoNPC.computedBillValues.totalAmountToCharge
            }
            if (data.result.paymentInfoNPC.computedBillValues.paymentAmountUpdated) {
              valor = data.result.paymentInfoNPC.computedBillValues.paymentAmountUpdated
            }
            if (valor === 0) {
              valor = data.result.validateBarCode.value


              if (data.result.validateBarCode.description) {
                this.descricao.traders.recipient = data.result.validateBarCode.description;
              }

              if (data.result.validateBarCode.dueDate) {
                this.descricao.dueDate = Moment(data.result.validateBarCode.dueDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
              }
            }
            const d = new Date();
            const n = d.getHours();
            if (data.result.validateBarCode.maxTime !== '' && (n <= 8 || n >= 18)) {
              const message = 'Este boleto só pode ser pago entre 8h e 18h';
              this.$validator.errors.add({ msg: message, field: 'codBarras' })
              this.$store.commit('message', { type: 'error', text: message });
              this.hasHourError = true;
            }
            if (valor) {
              this.valor = valor
              this.disableValor = true
            }
          }).catch((err, data) => {
            // set variable string message if array get first position or object get message or string set message
            console.log(data);
            const message = err;

            // this.$validator.errors.add({ msg: message, field: 'codBarras' })
            this.$store.commit('message', { type: 'error', text: message });
            // const codBarras = window.document.getElementsByName('codBarras')[0];
            // codBarras.select();
            // codBarras.focus();
          }).finally(() => {
            this.loading = false;
            // this.$store.commit('pageLoading', false);
            this.$store.commit('formLoading', false);
          });
      })
    },
    setInitialValues() {
      this.codBarras = '';
      this.valor = '';
      this.disableValor = false;
      this.descricao = {
        traders: {}
      };
    },
    validateBeforeSubmit() {
      this.$store.commit('message', { type: 'error', text: '' });
      if (this.$store.state.formLoading) return false
      this.$store.commit('formLoading', true)
      this.$validator.validateAll().then(() => {
        const { valor } = this
        const { codBarras, idContaCartaoDebitado } = this
        const cpfcnpj = this.$store.state.user.cpf || this.$store.state.user.cnpj
        const postData = {
          codBarras,
          valor,
          cpfcnpj,
          idContaCartaoDebitado
        }
        let barCodeCleaned = postData.codBarras;
        barCodeCleaned = barCodeCleaned.replace(/ /i, '');
        barCodeCleaned = barCodeCleaned.replace(/\./g, '');
        barCodeCleaned = barCodeCleaned.replace(/-/g, '');
        this.$http.post('/transfers/payments', {
          idAccount: postData.idContaCartaoDebitado,
          barCodeNumber: barCodeCleaned,
          amount: postData.valor,
          description: 'Pagamento solicitado pelo usuário',
          assignor: this.descricao.traders.recipient,
          assignorDocument: this.descricao.traders.recipientDocument,
          dueDate: Moment(this.descricao.dueDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          payer: {
            name: this.$store.state.user.nome,
            documentNumber: this.$store.state.user.cpf || this.$store.state.user.cnpj
          }
        })
          .then(() => {
            // this.$store.commit('message', { type: 'info', text: '' });
            // console.log(data);
            Swal.fire({
              html: `
                <span class="material-symbols-outlined" style="font-size:3.5rem; color:#7066e0;">check_circle</span>

                <p style="line-height:21px; margin-top: 15px; font-size: 18px;">
                  <strong>Sua transação está em análise.</strong>
                  </br>
                  <strong>Consulte em suas despesas</strong>
                  </br> 
                  <strong>se realmente foi concluída.</strong>
                </p>
              `,
              confirmButtonText: 'Ok',
              confirmButtonColor: '#7066e0'
            })
            this.valor = ''
            this.codBarras = ''
            this.descricao = {
              traders: {
                recipient: ''
              }
            }
            this.$router.push('/');
          }).catch((err) => {
            this.$validator.errors.add({ msg: err.message, field: 'codBarras' })
            // this.$store.commit('message', { type: 'error', text: err.message });
          }).finally(() => {
            this.$store.commit('formLoading', false);
          });
      })
    },
    nextStep() {
      Swal.fire({
        title: 'Pagamento realizado com sucesso :)',
        text: 'Um e-mail de confirmação será automaticamente enviado quando seu pagamento for registrado pelo banco.',
        type: 'success',
        confirmButtonText: 'Ok'
      })
      // console.log(card)
      // this.idContaCartaoDebitado = card.idConta.toString()
    },
    getMinutesOfDay(m) {
      return m.minutes() + m.hours() * 60;
    },
    getBankHour() {
      const date = new Date();
      const hours = date.getHours();
      const day = date.getDay();
      if (day !== 0 && day !== 6) {
        if (hours < 8 || hours >= 18) {
          const message = 'Boletos só podem ser pagos entre 8h e 18h';
          this.$store.commit('message', { type: 'error', text: message });
          this.hasHourError = true;
        }
      } else {
        const message = 'Boletos só podem ser pagos em dias úteis'
        this.$store.commit('message', { type: 'error', text: message });
        this.hasHourError = true;
      }
    },
    async permitedOperation() {
      await this.$http.get('/utils/check-restricao')
        .catch((data) => {
          console.log('data', data)
          // show message and redirect to home
          Swal.fire({
            type: 'error',
            title: data || 'Transação não permitida nesse horário',
            confirmButtonText: 'Ok',
            customClass: {
              title: 'px-5'
            },
          }).then(() => {
            this.$router.push('/')
          }).finally(() => {
            this.$router.push('/')
          })
        })
    }
  },
  async mounted() {
    await this.permitedOperation()
  }
  // mounted() {
  //   Swal.fire(
  //     'AVISO!',
  //     `<p style="text-align:left;">
  //     Caro Usuário,<br><br>
  //     Site disponível para consulta de saldo e desbloqueio do seu cartão.<br><br>
  //     Demais funcionalidades como pagamento de boleto, transferência entre cartões, transferência e recarga de celular estarão disponíveis em breve.<br><br>
  //     Equipe OnlyPay.
  //     </p>`,
  //     'info'
  //   )
  // }
};
</script>

<template>
  <div class="wrap">
    <v-loading v-model="loading" />
    <v-steps :current="idContaCartaoDebitado ? 2 : 1" :length="2" class="transfer__header"/>
    <div v-if="!idContaCartaoDebitado">
      <p>Selecione o cartão que deseja que seja debitado o valor do pagamento.</p>
      <br>
      <p style="font-weight: 700;font-size: small;padding: 5px 0;">Atenção, ao realizar pagamentos
        certifique-se do horário permitido (7h00 hs até as 16h00 hs, em dias úteis), caso contrário
        o pagamento deverá ser realizado somente no dia útil seguinte!</p>
      <v-cards v-on:click="nextStep" :showActions="false" :showBalance="true"/>
    </div>
    <form v-else @submit.prevent="validateBeforeSubmit" class="form-charge">
      <!-- <div class="textbox">
        <label class="textbox__label">Título e contas:</label>
        <select v-model="titulo" class="textbox__input" required>
          <option value="">Selecione</option>
        </select>
      </div> -->
      <v-textbox
        v-model="codBarras"
        data-vv-as="Código de barras"
        label="Digite o código de barras:"
        name="codBarras"
        :mask="[
        '#####.#####.#####.######.#####.######.#.##############',
        '###########-#.###########-#.###########-#.###########-#'
        ]"
        type="text"
        v-debounce:850ms="updateCodBarras"
        required/>
        <p v-if="descricao.traders.recipient || descricao.traders.payerName">
          Descrição: <br>
          <strong> {{ descricao.traders.recipient || descricao.traders.payerName || ""}}</strong> <br>
          <template v-if="descricao.billValue">
            <strong>Valor:</strong> {{MoneyMask(descricao.billValue)}} <br>
          </template>
          <template v-if="descricao.dueDate && descricao.dueDate.indexOf('date') === -1">
            <strong>Vencimento:</strong>
            {{descricao.dueDate}}
          </template>
          <br><br>
        </p>
        <v-textbox
          v-model="valor"
          label="Valor do título:"
          data-vv-as="Valor do título"
          money
          :disabled="disableValor || (!descricao.traders.recipient && !descricao.traders.payerName)"
          name="valor"
          required
          />
          <label class="checkbox_wrap" v-if="(descricao.traders.recipient || descricao.traders.payerName) && !hasHourError">
            <input type="checkbox" required>
            <span style="min-width: 150px; white-space: nowrap;">
              Confirme os dados de pagamento
            </span>
          </label>
        <v-button label="Pagar" :class="['block__form__actions__submit', {'disabled': (!descricao.traders.recipient && !descricao.traders.payerName) || hasHourError}]"/>
    </form>
  </div>
</template>

<style lang="postcss" scoped>
.checkbox_wrap{
  input{
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
  span{
    display: inline-block;
    vertical-align: middle;
  }
}
.wrap{
  position: relative;
  @media (max-width: 992px) {
    background-color: #fff;
    padding-top: 70px;
  }
}
.block__form__actions__submit{
  margin-top: 2rem;
}
.form-charge{
  background-color: #fff;
  padding-top: 2rem;
  padding-bottom: 5rem;
  @media (min-width: 992px) {
    padding-top: 0;
    max-width: 500px;
  }
}
</style>
