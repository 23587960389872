<template>
  <div
    v-if="value"
    class="row justify-content-center align-items-center"
    id="loading">
    <!-- <div class="lds-dual-ring"></div> -->
    <div class="loading">
      <div class="page-loading__content">
        <div class="page-loading__content__ring"><div/><div/><div/><div/></div>
        <svg class="page-loading__content__img" viewBox="0 0 634.905 340.881">
          <path
            fill="#006EB7"
            d="M169.954,0c97.883,0,170.926,73.046,170.926,170.928c0,96.907-73.044,169.952-170.926,169.952
            C73.048,340.881,0,267.835,0,170.928C0,73.046,73.048,0,169.954,0z M170.442,263.939c53.565,0,
            90.089-40.418,90.089-93.011 c0-53.569-36.524-93.499-90.089-93.499c-52.107,0-90.091,39.93-90.091,
            93.499C80.351,223.521,118.335,263.939,170.442,263.939z"/>
          <path
            fill="#006EB7"
            d="M512.676,4.87H429.89v71.098h69.638c28.73,0,50.645,18.993,50.645,51.131 c0,30.195-21.915,
            50.16-50.645,50.16H429.89v71.098h82.785c65.74,0,122.229-45.29,122.229-120.769 C634.905,43.339,
            578.416,4.87,512.676,4.87z"/>
          <path
            fill="#00FF00"
            d="M369.961,5.114h59.93v331.141h-81.833L348.043,98.14l-39.877,21.087l-30.683-61.855L369.961,5.114z"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  data() {
    return {}
  },
  mounted() {
    window.addEventListener('error', () => {
      this.$emit('input', false);
    });
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="postcss" scoped>
/* #loading{
  position: absolute;
  z-index: 9999;
  height: 100%;
  width: 100%;
  left: -2%;
  top: -2%;
  background-color: rgba(0,0,0,0.15);
  box-shadow: 10px 10px 15px rgba(0,0,0,0.25);
  border-radius: 5px;
} */
  .loading {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    position: fixed 0 0 58px 0;
    z-index: 1000;
    cursor: wait;
    @media (min-width: 992px) {
      bottom: 0;
      top: 96px;
    }
  }
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: var(--color-blue-onlypay) transparent var(--color-blue-onlypay) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
